.upperFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
}


.FooterLink{
    text-decoration: none;
    color: #84847a;
}

.lowerFooter{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
    padding-top: 20px;
}

@media only screen and (max-width: 1200px)
{

}

@media only screen and (max-width: 992px)
{

}

@media only screen and (max-width: 768px)
{
    .image{
        margin: 0px;
    }
    
    .imgcol{
        padding : 0px;
    }

    .title{
        font-size: 20px;
    }
    .upperFooter{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 14px;
    }
    .upperFooter p{
        display: flex;
        flex-direction: row;
        justify-content: center;
    } 
    .lowerFooter{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 12px;
    }
    .lowerFooter div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 576px)
{

}






