/* additional css */

.content5.cid-szWdSGiEXu p {
  color: #000;
}

blockquote p {
  color: black !important;
}
.card-title {
  font-weight: 400 !important;
  font-size: 1.375rem !important;
  margin-bottom: 0.35em !important;
  font-family: "Jost", sans-serif;
  margin-top: 5px !important;
  color: #20648e;
  line-height: 2rem !important;
  max-height: 7.66rem !important;
}
.card-desc {
  margin-top: 0.4rem;
  margin-bottom: 1rem;
  font-style: italic;
  font-family: "Jost", sans-serif;
  font-size: 1.2rem !important;
  font-style: italic !important;
  color: #474747;
}

.input-field {
  padding-left: 30px !important;
  height: 60px !important;
  border: none !important;
}
.input-field:focus {
  box-shadow: none !important;
}
.border-left-lg {
  border-radius: 30px 0px 0px 30px !important;
}

.border-right-lg {
  border-radius: 0px 30px 30px 0px !important;
}

.btn-height {
  min-height: 60px !important;
}
.border-radius-none {
  border-radius: 0px !important;
}
.cid-szWe9F6u0t.footer3 {
  background-color: rgb(223 222 221) !important;
}

.cid-szWdSGiEXu p {
  color: #000 !important;
}
.link-no-underline a {
  text-decoration: none !important;
}
.section-bg p a {
  color: #fff;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.carousel-indicators button{
  width:10px !important;
  height:10px !important;
  border-radius: 50%;
}

.cid-szWdSGiEXu ul li,.cid-szWdSGiEXu h2{
  text-align: left;
}

.cid-szWdSGiEXu ul li,.cid-szWdSGiEXu h2{
  text-align: left;
}
.MuiDrawer-paper{
  width: 50%;
}