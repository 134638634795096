* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


header {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07),
        0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.menus {
    display: flex;
    list-style: none;
}

.mr-3>.menu-items {
    margin-right: 40px;
}

.menu-items {
    display: flex;
    position: relative;
    align-items: center;
}

.menu-items a {
    display: block;
    color: #010090 !important;
    font-weight: 600 !important;
    padding: 16px 16px !important;
    font-size: 1rem;
    text-decoration: none;
    text-transform: capitalize !important;
    font-family: sans-serif !important;
}

.menu-items:hover {
    background-color: #010099;
    border-radius: 5px;
}

.menu-items:hover a {
    color: #fff !important;
}

.menu-items a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.menu-items a:hover,
.menu-items button:hover {
    background-color: #010099;
    border-radius: 5px;
    color: #fff;
}

.arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
}

.dropdown {
    position: absolute !important;
    top: 45px;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
        0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 1rem;
    z-index: 9999;
    min-width: 185px;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #010099 !important;
    color: #fff !important;
    border-radius: 0.5rem;
    display: none;
}

.dropdown.show {
    display: block;
}

.dropdown.dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
}