.topMargin {
  margin-top: 71px;

}



.title {
  font-weight: 700;
  font-size: 14px;
  /* margin-top: 100px; */
}

.child {
  /* height:100vh; */
  /* margin-top: 72px; */
}

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.image {
  background-size: cover;
  height: 500px;
  /* max-width: 1200px; */
  position: relative;
  margin-bottom: 20px;
  padding: 20px;
}

.bread {
  font-weight: 700;
  font-size: 20px;
  color: #979797;

}

.sectionContent {}

.entrylink {
  color: #0d6efd;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}

.breadcrumb {
  font-size: 14px;
  /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
}

.breadcrumb a {
  text-decoration: none;
}

.subscribed {
  color: #979797;
  cursor: pointer;
}

.unsubscribed {
  color: blue;
  cursor: pointer;
}

.remove {
  color: blue;
}

/* .text{
    max-width: 1200px;
  } */

.text p {
  font-size: 14px;
}

.image img {
  height: inherit;
  width: 75%;
}

.text {
  position: relative;
  font-size: 20px;
}

.pagetext {
  font-size: 30px;
  background: #03c;
}

.titletext {
  position: relative;
  z-index: 1;
  color: white;
}

.ColPadding {
  padding: 1rem;
}

/* .footer{
    width: 100%;
    position: absolute;
    bottom: 0;
  } */