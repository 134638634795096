.textform{
    width: 100%;
    border-right: 1px solid rgb(223 219 219) !important;
    border-left: 1px solid rgb(223 219 219) !important;
    border-top: 1px solid rgb(223 219 219) !important;
    border-bottom: 2px solid rgb(223 219 219) !important ;
    height: 100%;
}

.textform div:before{
    border-bottom: 0px !important;
    /* border-radius: 0px; */
}

.textform div{
    background-color: white !important;
    /* border-bottom: 1px solid rgb(223 219 219) !important; */
    border-radius: 0px;
    /* border-right: 2px solid rgb(223 219 219);
    border-left: 2px solid rgb(223 219 219);
    border-top: 2px solid rgb(223 219 219); */
}

.empty{
    width: 100%;
    border-right: 2px solid red;
    border-left: 2px solid red;
    border-top: 2px solid red;
    
}

.empty div:before{
    border-bottom: 0px !important;
}

.empty div{
    background-color: white !important;
    border-bottom: 2px solid red !important;
}

@media only screen and (max-width: 1200px)
{

}

@media only screen and (max-width: 992px)
{

}

@media only screen and (max-width: 768px)
{
    .textform{
        height: 62px;
        padding-bottom: 5px;
        /* border-bottom: 2px solid rgb(223 219 219) !important; */
    }
    .textform div{
        background-color: white !important;
        height: 62px;
        width: 100%;
        /* border-bottom: 2px solid rgb(223 219 219) !important; */
    }
    .empty{
        height: 62px;
        width: 80%;
        margin-left: 20px;
        padding-bottom: 5px;
    }
    .empty div{
        background-color: white !important;
        height: 62px;
        width: 100%;
    }
}

@media only screen and (max-width: 576px)
{

}

