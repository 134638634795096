.Warning{
    color : white;
    width: 100%;
    background-color: #010090;
    height: fit-content;
    position: fixed;
    z-index: 100;
    top: 0;
    font-size: 15px;
}

.warningwrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ClearIcon{
    cursor: pointer;
    font-size: 25px;
}

.warningtext{
    width: 80%;
}