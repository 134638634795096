.Contact {
  margin-top: 72px;
}

.title {
  font-weight: 700;
  font-size: 24px;
}

.contacttitle {
  font-weight: 700;
  font-size: 14px;
}

.description {
  font-size: 14px;
  color: #3f3d3a;
}

.bread {
  font-weight: 700;
  font-size: 20px;
  color: #979797;
}

.breadcrumb {
  font-size: 14px;
}

.breadcrumb a {
  text-decoration: none;
}

.contactdesc {
  font-size: 14px;
}

.condescription {
  width: 70%;
}

.items {
  margin-left: 20px;
  cursor: pointer;
  padding-right: 10px;
  font-size: 22px;
  line-height: 30px;
}
.active {
  color: #100090;
}
.items:not(:last-child) {
  border-right: 1px solid black;
}
.cardImage {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image {
  /* border-radius: 50%; */
  width: 100px !important;
  height: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}
.pageTitleText {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: 0px;
}
