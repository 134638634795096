.sliderImage {
  display: block;
  width: 100%;
  /* object-fit: contain; */
}

.sliderItem {
  /* max-height: 80vh; */
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

@media (max-width: 575.98px) {
  .sliderItem {
    max-height: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .sliderItem {
    max-height: 100%;
  }

}

@media (min-width: 768px) and (max-width: 991.98px) {
  .sliderItem {
    max-height: 100%;
  }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .sliderItem {
    /* position: absolute; */
    max-height: 100%;


  }

  .sliderImage {
    height: inherit;
    width: 100%;
    max-height: 80vh;
  }
}

@media (min-width: 1200px) {
  .sliderItem {
    /* position: absolute; */
    max-height: 100%;
  }
  .sliderImage {
    height: inherit;
    width: 100%;
    max-height: 80vh;
  }

}

.top {
  /* position: absolute; */
  color: white;
}

.back {
  background-color: #e9ecef;
  height: 500px;
  /* margin-top: 104px; */
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}