body {
  font-family: Jost;
}

.display-1 {
  font-family: 'Jost', sans-serif;
  font-size: 4.6rem;
  line-height: 1.1;
}

.display-1>.mbr-iconfont {
  font-size: 5.75rem;
}

.display-2 {
  font-family: 'Jost', sans-serif;
  font-size: 3rem;
  line-height: 1.1;
}

.display-2>.mbr-iconfont {
  font-size: 3.75rem;
}

.display-4 {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem !important;
  line-height: 1.5;
}

.display-4>.mbr-iconfont {
  font-size: 1.375rem;
}

.display-5 {
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 1.5;
}

.display-5>.mbr-iconfont {
  font-size: 2.5rem;
}

.display-7 {
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  line-height: 1.5 !important;
}

.display-7>.mbr-iconfont {
  font-size: 1.5rem;
}

/* ---- Fluid typography for mobile devices ---- */
/* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
/* 100vw - current viewport width */
/* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
/* 0.65 - min scale variable, may vary */
@media (max-width: 992px) {
  .display-1 {
    font-size: 3.68rem;
  }
}

@media (max-width: 768px) {
  .display-1 {
    font-size: 3.22rem;
    font-size: calc(2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.1 * (2.26rem + (4.6 - 2.26) * ((100vw - 20rem) / (48 - 20))));
  }

  .display-2 {
    font-size: 2.4rem;
    font-size: calc(1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.3 * (1.7rem + (3 - 1.7) * ((100vw - 20rem) / (48 - 20))));
  }

  .display-4 {
    font-size: 0.88rem;
    font-size: calc(1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
  }

  .display-5 {
    font-size: 1.6rem;
    font-size: calc(1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (1.35rem + (2 - 1.35) * ((100vw - 20rem) / (48 - 20))));
  }

  .display-7 {
    font-size: 0.96rem;
    font-size: calc(1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc(1.4 * (1.07rem + (1.2 - 1.07) * ((100vw - 20rem) / (48 - 20))));
  }
}

/* Buttons */
.btn {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}

.btn-sm {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}

.btn-md {
  padding: 0.6rem 1.2rem;
  border-radius: 4px;
}

.btn-lg {
  padding: 1rem 2.6rem;
  border-radius: 4px;
}

.bg-primary {
  background-color: #000066 !important;
}

.bg-success {
  background-color: #40b0bf !important;
}

.bg-info {
  background-color: #47b5ed !important;
}

.bg-warning {
  background-color: #ffe161 !important;
}

.bg-danger {
  background-color: #ff9966 !important;
}

.btn-primary,
.btn-primary:active {
  background-color: #000066 !important;
  border-color: #000066 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary.active {
  color: #ffffff !important;
  background-color: #00000f !important;
  border-color: #00000f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #ffffff !important;
  background-color: #00000f !important;
  border-color: #00000f !important;
}

.btn-secondary,
.btn-secondary:active {
  background-color: #ff6666 !important;
  border-color: #ff6666 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary.active {
  color: #ffffff !important;
  background-color: #ff0f0f !important;
  border-color: #ff0f0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #ffffff !important;
  background-color: #ff0f0f !important;
  border-color: #ff0f0f !important;
}

.btn-info,
.btn-info:active {
  background-color: #47b5ed !important;
  border-color: #47b5ed !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-info:hover,
.btn-info:focus,
.btn-info.focus,
.btn-info.active {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #ffffff !important;
  background-color: #148cca !important;
  border-color: #148cca !important;
}

.btn-success,
.btn-success:active {
  background-color: #40b0bf !important;
  border-color: #40b0bf !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-success:hover,
.btn-success:focus,
.btn-success.focus,
.btn-success.active {
  color: #ffffff !important;
  background-color: #2a747e !important;
  border-color: #2a747e !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.section-bg {
  background-color: #010099 !important;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #ffffff !important;
  background-color: #2a747e !important;
  border-color: #2a747e !important;
}

.btn-warning,
.btn-warning:active {
  background-color: #ffe161 !important;
  border-color: #ffe161 !important;
  color: #614f00 !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning.focus,
.btn-warning.active {
  color: #0a0800 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #614f00 !important;
  background-color: #ffd10a !important;
  border-color: #ffd10a !important;
}

.btn-danger,
.btn-danger:active {
  background-color: #ff9966 !important;
  border-color: #ff9966 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger.focus,
.btn-danger.active {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #ffffff !important;
  background-color: #ff5f0f !important;
  border-color: #ff5f0f !important;
}

.btn-white,
.btn-white:active {
  background-color: #fafafa !important;
  border-color: #fafafa !important;
  color: #7a7a7a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-white:hover,
.btn-white:focus,
.btn-white.focus,
.btn-white.active {
  color: #4f4f4f !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-white.disabled,
.btn-white:disabled {
  color: #7a7a7a !important;
  background-color: #cfcfcf !important;
  border-color: #cfcfcf !important;
}

.btn-black,
.btn-black:active {
  background-color: #232323 !important;
  border-color: #232323 !important;
  color: #ffffff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.btn-black:hover,
.btn-black:focus,
.btn-black.focus,
.btn-black.active {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn-black.disabled,
.btn-black:disabled {
  color: #ffffff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.btn-primary-outline,
.btn-primary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #000066;
}

.btn-primary-outline:hover,
.btn-primary-outline:focus,
.btn-primary-outline.focus,
.btn-primary-outline.active {
  color: #00000f !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-primary-outline.disabled,
.btn-primary-outline:disabled {
  color: #ffffff !important;
  background-color: #000066 !important;
  border-color: #000066 !important;
}

.btn-secondary-outline,
.btn-secondary-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ff6666;
}

.btn-secondary-outline:hover,
.btn-secondary-outline:focus,
.btn-secondary-outline.focus,
.btn-secondary-outline.active {
  color: #ff0f0f !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-secondary-outline.disabled,
.btn-secondary-outline:disabled {
  color: #ffffff !important;
  background-color: #ff6666 !important;
  border-color: #ff6666 !important;
}

.btn-info-outline,
.btn-info-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #47b5ed;
}

.btn-info-outline:hover,
.btn-info-outline:focus,
.btn-info-outline.focus,
.btn-info-outline.active {
  color: #148cca !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-info-outline.disabled,
.btn-info-outline:disabled {
  color: #ffffff !important;
  background-color: #47b5ed !important;
  border-color: #47b5ed !important;
}

.btn-success-outline,
.btn-success-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #40b0bf;
}

.btn-success-outline:hover,
.btn-success-outline:focus,
.btn-success-outline.focus,
.btn-success-outline.active {
  color: #2a747e !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-success-outline.disabled,
.btn-success-outline:disabled {
  color: #ffffff !important;
  background-color: #40b0bf !important;
  border-color: #40b0bf !important;
}

.btn-warning-outline,
.btn-warning-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ffe161;
}

.btn-warning-outline:hover,
.btn-warning-outline:focus,
.btn-warning-outline.focus,
.btn-warning-outline.active {
  color: #ffd10a !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-warning-outline.disabled,
.btn-warning-outline:disabled {
  color: #614f00 !important;
  background-color: #ffe161 !important;
  border-color: #ffe161 !important;
}

.btn-danger-outline,
.btn-danger-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #ff9966;
}

.btn-danger-outline:hover,
.btn-danger-outline:focus,
.btn-danger-outline.focus,
.btn-danger-outline.active {
  color: #ff5f0f !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-danger-outline.disabled,
.btn-danger-outline:disabled {
  color: #ffffff !important;
  background-color: #ff9966 !important;
  border-color: #ff9966 !important;
}

.btn-black-outline,
.btn-black-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #232323;
}

.btn-black-outline:hover,
.btn-black-outline:focus,
.btn-black-outline.focus,
.btn-black-outline.active {
  color: #000000 !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-black-outline.disabled,
.btn-black-outline:disabled {
  color: #ffffff !important;
  background-color: #232323 !important;
  border-color: #232323 !important;
}

.btn-white-outline,
.btn-white-outline:active {
  background-color: transparent !important;
  border-color: transparent;
  color: #fafafa;
}

.btn-white-outline:hover,
.btn-white-outline:focus,
.btn-white-outline.focus,
.btn-white-outline.active {
  color: #cfcfcf !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-white-outline.disabled,
.btn-white-outline:disabled {
  color: #7a7a7a !important;
  background-color: #fafafa !important;
  border-color: #fafafa !important;
}
.text-left{
  text-align: left;
}
.border-none{
  border:none !important;
  border-top:none !important;
  border-left: none !important;
  border-bottom: none !important;
  border-right: none !important;
}

.text-primary {
  color: #000066 !important;
}

.text-secondary {
  color: #ff6666 !important;
}

.text-success {
  color: #40b0bf !important;
}

.text-info {
  color: #47b5ed !important;
}

.text-warning {
  color: #ffe161 !important;
}

.text-danger {
  color: #ff9966 !important;
}

.text-white {
  color: #fafafa !important;
}

.text-black {
  color: #232323 !important;
}

a.text-primary:hover,
a.text-primary:focus,
a.text-primary.active {
  color: #000000 !important;
}

a.text-secondary:hover,
a.text-secondary:focus,
a.text-secondary.active {
  color: #ff0000 !important;
}

a.text-success:hover,
a.text-success:focus,
a.text-success.active {
  color: #266a73 !important;
}

a.text-info:hover,
a.text-info:focus,
a.text-info.active {
  color: #1283bc !important;
}

a.text-warning:hover,
a.text-warning:focus,
a.text-warning.active {
  color: #facb00 !important;
}

a.text-danger:hover,
a.text-danger:focus,
a.text-danger.active {
  color: #ff5500 !important;
}

a.text-white:hover,
a.text-white:focus,
a.text-white.active {
  color: #c7c7c7 !important;
}

a.text-black:hover,
a.text-black:focus,
a.text-black.active {
  color: #000000 !important;
}

a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption) {
  position: relative;
  background-image: transparent;
  background-size: 10000px 2px;
  background-repeat: no-repeat;
  background-position: 0px 1.2em;
  background-position: -10000px 1.2em;
}

a[class*="text-"]:not(.nav-link):not(.dropdown-item):not([role]):not(.navbar-caption):hover {
  transition: background-position 2s ease-in-out;
  background-image: linear-gradient(currentColor 50%, currentColor 50%);
  background-position: 0px 1.2em;
}

.nav-tabs .nav-link.active {
  color: #000066;
}

.nav-tabs .nav-link:not(.active) {
  color: #232323;
}

.alert-success {
  background-color: #70c770;
}

.alert-info {
  background-color: #47b5ed;
}

.alert-warning {
  background-color: #ffe161;
}

.alert-danger {
  background-color: #ff9966;
}

.mbr-gallery-filter li.active .btn {
  background-color: #000066;
  border-color: #000066;
  color: #ffffff;
}

.mbr-gallery-filter li.active .btn:focus {
  box-shadow: none;
}

a,
a:hover {
  color: #000066;
}

.mbr-plan-header.bg-primary .mbr-plan-subtitle,
.mbr-plan-header.bg-primary .mbr-plan-price-desc {
  color: #0000e6;
}

.mbr-plan-header.bg-success .mbr-plan-subtitle,
.mbr-plan-header.bg-success .mbr-plan-price-desc {
  color: #a0d8df;
}

.mbr-plan-header.bg-info .mbr-plan-subtitle,
.mbr-plan-header.bg-info .mbr-plan-price-desc {
  color: #ffffff;
}

.mbr-plan-header.bg-warning .mbr-plan-subtitle,
.mbr-plan-header.bg-warning .mbr-plan-price-desc {
  color: #ffffff;
}

.mbr-plan-header.bg-danger .mbr-plan-subtitle,
.mbr-plan-header.bg-danger .mbr-plan-price-desc {
  color: #ffffff;
}

/* Scroll to top button*/
.scrollToTop_wraper {
  display: none;
}

.form-control {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}

.form-control>.mbr-iconfont {
  font-size: 1.375rem;
}

.form-control:hover,
.form-control:focus {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px 0px, rgba(0, 0, 0, 0.07) 0px 1px 3px 0px, rgba(0, 0, 0, 0.03) 0px 0px 0px 1px;
  border-color: #000066 !important;
}

.form-control:-webkit-input-placeholder {
  font-family: 'Jost', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
}

.form-control:-webkit-input-placeholder>.mbr-iconfont {
  font-size: 1.375rem;
}

blockquote {
  border-color: #000066;
}

/* Forms */
.jq-selectbox li:hover,
.jq-selectbox li.selected {
  background-color: #000066;
  color: #ffffff;
}

.jq-number__spin {
  transition: 0.25s ease;
}

.jq-number__spin:hover {
  border-color: #000066;
}

.jq-selectbox .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:after,
.jq-number__spin.plus:after {
  transition: 0.4s;
  border-top-color: #353535;
  border-bottom-color: #353535;
}

.jq-selectbox:hover .jq-selectbox__trigger-arrow,
.jq-number__spin.minus:hover:after,
.jq-number__spin.plus:hover:after {
  border-top-color: #000066;
  border-bottom-color: #000066;
}

.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default,
.xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div.xdsoft_current {
  color: #ffffff !important;
  background-color: #000066 !important;
  box-shadow: none !important;
}

.xdsoft_datetimepicker .xdsoft_calendar td:hover,
.xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box>div>div:hover {
  color: #000000 !important;
  background: #ff6666 !important;
  box-shadow: none !important;
}

.lazy-bg {
  background-image: none !important;
}

.lazy-placeholder:not(section),
.lazy-none {
  display: block;
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
  height: auto;
}

iframe.lazy-placeholder,
.lazy-placeholder:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 200px;
  background: transparent no-repeat center;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='32' height='32' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg' stroke='%23000066' %3e%3cg fill='none' fill-rule='evenodd'%3e%3cg transform='translate(16 16)' stroke-width='2'%3e%3ccircle stroke-opacity='.5' cx='16' cy='16' r='16'/%3e%3cpath d='M32 16c0-9.94-8.06-16-16-16'%3e%3canimateTransform attributeName='transform' type='rotate' from='0 16 16' to='360 16 16' dur='1s' repeatCount='indefinite'/%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

section.lazy-placeholder:after {
  opacity: 0.5;
}

body {
  overflow-x: hidden;
}

a {
  transition: color 0.6s;
}

.cid-szWd4exHx0 {
  z-index: 1000;
  width: 100%;
  position: relative;
  min-height: 80px;
}

.cid-szWd4exHx0 nav.navbar {
  position: fixed;
}

.cid-szWd4exHx0 .dropdown-item:before {
  font-family: Moririse2 !important;
  content: "\e966";
  display: inline-block;
  width: 0;
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  margin-right: 0.5rem;
  line-height: 1;
  font-size: inherit;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  transform: scale(0, 1);
  transition: all 0.25s ease-in-out;
}

.cid-szWd4exHx0 .dropdown-menu {
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.cid-szWd4exHx0 .dropdown-item {
  border-bottom: 1px solid #e6e6e6;
}

.cid-szWd4exHx0 .dropdown-item:hover,
.cid-szWd4exHx0 .dropdown-item:focus {
  background: #000066 !important;
  color: white !important;
}

.cid-szWd4exHx0 .dropdown-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.cid-szWd4exHx0 .dropdown-item:last-child {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cid-szWd4exHx0 .nav-dropdown .link {
  padding: 0 0.3em !important;
  margin: 0.667em 1em !important;
}

.cid-szWd4exHx0 .nav-dropdown .link.dropdown-toggle::after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.cid-szWd4exHx0 .nav-link {
  position: relative;
}

.cid-szWd4exHx0 .container {
  display: flex;
  margin: auto;
}

.cid-szWd4exHx0 .iconfont-wrapper {
  color: #000000 !important;
  font-size: 1.5rem;
  padding-right: 0.5rem;
}

.cid-szWd4exHx0 .dropdown-menu,
.cid-szWd4exHx0 .navbar.opened {
  background: #010099 !important;
}

.cid-szWd4exHx0 .nav-item:focus,
.cid-szWd4exHx0 .nav-link:focus {
  outline: none;
}

.cid-szWd4exHx0 .dropdown .dropdown-menu .dropdown-item {
  width: auto;
  transition: all 0.25s ease-in-out;
}

.cid-szWd4exHx0 .dropdown .dropdown-menu .dropdown-item::after {
  right: 0.5rem;
}

.cid-szWd4exHx0 .dropdown .dropdown-menu .dropdown-item .mbr-iconfont {
  margin-right: 0.5rem;
  vertical-align: sub;
}

.cid-szWd4exHx0 .dropdown .dropdown-menu .dropdown-item .mbr-iconfont:before {
  display: inline-block;
  transform: scale(1, 1);
  transition: all 0.25s ease-in-out;
}

.cid-szWd4exHx0 .collapsed .dropdown-menu .dropdown-item:before {
  display: none;
}

.cid-szWd4exHx0 .collapsed .dropdown .dropdown-menu .dropdown-item {
  padding: 0.235em 1.5em 0.235em 1.5em !important;
  transition: none;
  margin: 0 !important;
}

.cid-szWd4exHx0 .navbar {
  min-height: 70px;
  transition: all 0.3s;
  border-bottom: 1px solid transparent;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background: #010099;
}

.cid-szWd4exHx0 .navbar.opened {
  transition: all 0.3s;
}

.cid-szWd4exHx0 .navbar .dropdown-item {
  padding: 0.5rem 1.8rem;
}

.cid-szWd4exHx0 .navbar .navbar-logo img {
  width: auto;
}

.cid-szWd4exHx0 .navbar .navbar-collapse {
  justify-content: flex-end;
  z-index: 1;
}

.cid-szWd4exHx0 .navbar.collapsed {
  justify-content: center;
}

.cid-szWd4exHx0 .navbar.collapsed .nav-item .nav-link::before {
  display: none;
}

.cid-szWd4exHx0 .navbar.collapsed.opened .dropdown-menu {
  top: 0;
}

@media (min-width: 992px) {
  .cid-szWd4exHx0 .navbar.collapsed.opened:not(.navbar-short) .navbar-collapse {
    max-height: calc(98.5vh - 3rem);
  }
}

.cid-szWd4exHx0 .navbar.collapsed .dropdown-menu .dropdown-submenu {
  left: 0 !important;
}

.cid-szWd4exHx0 .navbar.collapsed .dropdown-menu .dropdown-item:after {
  right: auto;
}

.cid-szWd4exHx0 .navbar.collapsed .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
  margin-left: 0.5rem;
  margin-top: 0.2rem;
  border-top: 0.35em solid;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
  border-bottom: 0;
  top: 41%;
}

.cid-szWd4exHx0 .navbar.collapsed ul.navbar-nav li {
  margin: auto;
}

.cid-szWd4exHx0 .navbar.collapsed .dropdown-menu .dropdown-item {
  padding: 0.25rem 1.5rem;
  text-align: center;
}

.cid-szWd4exHx0 .navbar.collapsed .icons-menu {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

@media (max-width: 991px) {
  .cid-szWd4exHx0 .navbar .nav-item .nav-link::before {
    display: none;
  }

  .cid-szWd4exHx0 .navbar.opened .dropdown-menu {
    top: 0;
  }

  .cid-szWd4exHx0 .navbar .dropdown-menu .dropdown-submenu {
    left: 0 !important;
  }

  .cid-szWd4exHx0 .navbar .dropdown-menu .dropdown-item:after {
    right: auto;
  }

  .cid-szWd4exHx0 .navbar .dropdown-menu .dropdown-toggle[data-toggle="dropdown-submenu"]:after {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
    border-top: 0.35em solid;
    border-right: 0.35em solid transparent;
    border-left: 0.35em solid transparent;
    border-bottom: 0;
    top: 40%;
  }

  .cid-szWd4exHx0 .navbar .navbar-logo img {
    height: 3rem !important;
  }

  .cid-szWd4exHx0 .navbar ul.navbar-nav li {
    margin: auto;
  }

  .cid-szWd4exHx0 .navbar .dropdown-menu .dropdown-item {
    padding: 0.25rem 1.5rem !important;
    text-align: center;
  }

  .cid-szWd4exHx0 .navbar .navbar-brand {
    flex-shrink: initial;
    flex-basis: auto;
    word-break: break-word;
    padding-right: 2rem;
  }

  .cid-szWd4exHx0 .navbar .navbar-toggler {
    flex-basis: auto;
  }

  .cid-szWd4exHx0 .navbar .icons-menu {
    padding-left: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.cid-szWd4exHx0 .navbar.navbar-short {
  min-height: 60px;
}

.cid-szWd4exHx0 .navbar.navbar-short .navbar-logo img {
  height: 2.5rem !important;
}

.cid-szWd4exHx0 .navbar.navbar-short .navbar-brand {
  min-height: 60px;
  padding: 0;
}

.cid-szWd4exHx0 .navbar-brand {
  min-height: 70px;
  flex-shrink: 0;
  align-items: center;
  margin-right: 0;
  /* padding: 10px 0; */
  transition: all 0.3s;
  word-break: break-word;
  z-index: 1;
}

.cid-szWd4exHx0 .navbar-brand .navbar-caption {
  line-height: inherit !important;
}

.cid-szWd4exHx0 .navbar-brand .navbar-logo a {
  outline: none;
}

.cid-szWd4exHx0 .dropdown-item.active,
.cid-szWd4exHx0 .dropdown-item:active {
  background-color: transparent;
}

.cid-szWd4exHx0 .navbar-expand-lg .navbar-nav .nav-link {
  padding: 0;
}

.cid-szWd4exHx0 .nav-dropdown .link.dropdown-toggle {
  margin-right: 1.667em;
}

.cid-szWd4exHx0 .nav-dropdown .link.dropdown-toggle[aria-expanded="true"] {
  margin-right: 0;
  padding: 0.667em 1.667em;
}

.cid-szWd4exHx0 .navbar.navbar-expand-lg .dropdown .dropdown-menu {
  background: #010099;
}

.cid-szWd4exHx0 .navbar.navbar-expand-lg .dropdown .dropdown-menu .dropdown-submenu {
  margin: 0;
  left: 100%;
}

.cid-szWd4exHx0 .navbar .dropdown.open>.dropdown-menu {
  display: block;
}

.cid-szWd4exHx0 ul.navbar-nav {
  flex-wrap: wrap;
}

.cid-szWd4exHx0 .navbar-buttons {
  text-align: center;
  min-width: 170px;
}

button.navbar-toggler {
  outline: none;
  width: 31px;
  height: 20px;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  align-self: center;
}

.cid-szWd4exHx0 button.navbar-toggler .hamburger span {
  position: absolute;
  right: 0;
  width: 30px;
  height: 2px;
  border-right: 5px;
  background-color: #fafafa;
}

.cid-szWd4exHx0 button.navbar-toggler .hamburger span:nth-child(1) {
  top: 0;
  transition: all 0.2s;
}

.cid-szWd4exHx0 button.navbar-toggler .hamburger span:nth-child(2) {
  top: 8px;
  transition: all 0.15s;
}

.cid-szWd4exHx0 button.navbar-toggler .hamburger span:nth-child(3) {
  top: 8px;
  transition: all 0.15s;
}

.cid-szWd4exHx0 button.navbar-toggler .hamburger span:nth-child(4) {
  top: 16px;
  transition: all 0.2s;
}

.cid-szWd4exHx0 nav.opened .hamburger span:nth-child(1) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}

.cid-szWd4exHx0 nav.opened .hamburger span:nth-child(2) {
  transform: rotate(45deg);
  transition: all 0.25s;
}

.cid-szWd4exHx0 nav.opened .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
  transition: all 0.25s;
}

.cid-szWd4exHx0 nav.opened .hamburger span:nth-child(4) {
  top: 8px;
  width: 0;
  opacity: 0;
  right: 50%;
  transition: all 0.2s;
}

.cid-szWd4exHx0 .navbar-dropdown {
  padding: 0 1rem;
  position: fixed;
}

.cid-szWd4exHx0 a.nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cid-szWd4exHx0 .icons-menu {
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  text-align: center;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
  .cid-szWd4exHx0 .navbar {
    height: 70px;
  }

  .cid-szWd4exHx0 .navbar.opened {
    height: auto;
  }

  .cid-szWd4exHx0 .nav-item .nav-link:hover::before {
    width: 175%;
    max-width: calc(100% + 2rem);
    left: -1rem;
  }
}

.cid-szWdkawVmG {
  display: flex;

}

.cid-szWdkawVmG .mbr-overlay {
  background-color: #2566db;
  opacity: 0.5;
}

.cid-szWdkawVmG .content-wrap {
  padding: 1rem 1rem;
}

.cid-szWdkawVmG .content-wrap p:last-child {
  margin-bottom: 0px !important;
}

@media (min-width: 768px) {
  .cid-szWdkawVmG {
    align-items: flex-start;
  }

  .cid-szWdkawVmG .row {
    justify-content: flex-start;
  }

  .cid-szWdkawVmG .content-wrap {
    width: 100%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .cid-szWdkawVmG .content-wrap {
    min-width: 100%;
  }
}

@media (max-width: 767px) {
  .cid-szWdkawVmG {
    -webkit-align-items: center;
    align-items: center;
  }

  .cid-szWdkawVmG .mbr-row {
    -webkit-justify-content: center;
    justify-content: center;
  }

  .cid-szWdkawVmG .content-wrap {
    width: 100%;
  }
}



.cid-szWdSGiEXu {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #fafafa;
}

.cid-szWdJmjpxZ {
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #ffffff;
}

.cid-szWdJmjpxZ img,
.cid-szWdJmjpxZ .item-img {
  width: 100%;
}

.cid-szWdJmjpxZ .item:focus,
.cid-szWdJmjpxZ span:focus {
  outline: none;
}

.cid-szWdJmjpxZ .item {
  cursor: pointer;
  margin-bottom: 2rem;
}

.cid-szWdJmjpxZ .item-wrapper {
  position: relative;
  border-radius: 4px;
  background: #fafafa;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

@media (min-width: 992px) {
  .cid-szWdJmjpxZ .item-wrapper .item-content {
    padding: 2rem 2rem 0;
  }

  .cid-szWdJmjpxZ .item-wrapper .item-footer {
    padding: 0 2rem 2rem;
  }
}

@media (max-width: 991px) {
  .cid-szWdJmjpxZ .item-wrapper .item-content {
    padding: 1rem 1rem 0;
  }

  .cid-szWdJmjpxZ .item-wrapper .item-footer {
    padding: 0 1rem 1rem;
  }
}

.cid-szWdJmjpxZ .mbr-section-btn {
  margin-top: auto !important;
}

.cid-szWdJmjpxZ .mbr-section-title {
  color: #232323;
}

.cid-szWdJmjpxZ .mbr-text,
.cid-szWdJmjpxZ .mbr-section-btn {
  text-align: left;
}

.cid-szWdJmjpxZ .item-title {
  text-align: left;
}

.cid-szWdJmjpxZ .item-subtitle {
  text-align: left;
}

.cid-szWocWgQOX {
  padding-top: 0rem;
  padding-bottom: 0rem;
  background-color: #47b5ed;
}

.mbr-text p {
  line-height: 1.5rem !important;
}

@media (min-width: 991px) {
  .cid-szWocWgQOX .container-fluid {
    padding-left: 0;
  }
}

.cid-szWocWgQOX .mbr-text,
.cid-szWocWgQOX .mbr-section-btn {
  color: #232323;
}

@media (max-width: 991px) {

  .cid-szWocWgQOX .mbr-text,
  .cid-szWocWgQOX .mbr-section-btn {
    text-align: center;
  }
}

@media (max-width: 991px) {

  .cid-szWocWgQOX .mbr-text,
  .cid-szWocWgQOX .mbr-section-title {
    text-align: center;
  }
}

.cid-szWocWgQOX a.btn {
  height: 100%;
  margin: 0;
}

.cid-szWocWgQOX .mbr-section-btn {
  display: flex;
  margin-bottom: 1rem;
  width: 100%;
}

.cid-szWocWgQOX .mbr-section-btn .btn {
  width: 100%;
}

@media (max-width: 991px) {
  .cid-szWocWgQOX .image-wrapper {
    margin-bottom: 2rem;
  }
}

.cid-szWocWgQOX .justify-content-center {
  align-items: center;
}

.cid-szWpH3l81p {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #ffffff;
}

.cid-szWpH3l81p blockquote {
  border-color: #010099;
  border-radius: 4px;
  background-color: #fafafa;
}

.cid-szWe9F6u0t {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #010099;
}

.cid-szWe9F6u0t .row-links {
  width: 100%;
  justify-content: center;
}

.cid-szWe9F6u0t .social-row {
  width: 100%;
  justify-content: center;
}

.cid-szWe9F6u0t .media-container-row {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cid-szWe9F6u0t .media-container-row .foot-menu {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}
.cid-szWe9F6u0t .footer-row .foot-menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
}

.cid-szWe9F6u0t .media-container-row .foot-menu li {
  padding: 0 1rem 0rem 1rem;
}

.cid-szWe9F6u0t .media-container-row .foot-menu li p {
  margin: 0;
}

.cid-szWe9F6u0t .media-container-row .social-list {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.cid-szWe9F6u0t .media-container-row .social-list .mbr-iconfont-social {
  font-size: 1.5rem;
  color: #ffffff;
}

.cid-szWe9F6u0t .media-container-row .social-list .soc-item {
  margin: 0 .5rem;
}

.cid-szWe9F6u0t .media-container-row .social-list a {
  margin: 0;
  opacity: .5;
  transition: .2s linear;
}

.cid-szWe9F6u0t .media-container-row .social-list a:hover {
  opacity: 1;
}

@media (max-width: 767px) {
  .cid-szWe9F6u0t .media-container-row .social-list {
    -webkit-justify-content: center;
    justify-content: center;
  }
}

.cid-szWe9F6u0t .media-container-row .row-copirayt {
  word-break: break-word;
  width: 100%;
}

.cid-szWe9F6u0t .media-container-row .row-copirayt p {
  width: 100%;
}