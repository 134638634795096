.searchpage {
    margin-top: 72px;
}

.search {
    margin-top: 17px;
    margin-left: -50px;
    font-size: 50px;
    cursor: pointer;
}

.inputflex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 90px;
}

.filter {
    font-size: 50px;
    cursor: pointer;
}

.heading {
    font-weight: 700;
    font-size: 22px;
}

.title {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    color: #4a4aea;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* .description b{
    font-weight: normal !important;
} */

.result {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.eachresult {}

@media (max-width: 575.98px) {
    .input {
        width: 100% !important;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .input {
        width: 100% !important;
    }

}

/* @media (min-width: 768px) and (max-width: 991.98px) {} */
.input {
    border-radius: 10px;
    width: 800px;
    height: 65px;
    padding-left: 20px;
}