.nav-item {
  display: inline-block;
  margin-right: 40px;
  display: flex;
  align-items: center;

}
.nav-item:hover{
  background-color: #010099;
  border-radius: 5px;
}
.nav-item a {
  display: block;
  color: #010090 !important;
  font-weight: 600 !important;
  padding: 14px 16px !important;
  font-size: 1rem;
  text-decoration: none;
  text-transform: capitalize !important;
  font-family: sans-serif !important;
}

.nav-item:hover a{
  color: #fff !important;
}

.MuiToolbar-root{
  display:flex;
  justify-content: space-between;
}

.MuiAppBar-colorPrimary {
  color: #000 !important;
  background-color: #fff !important;
}

.MuiIconButton-root {
  color: #010090 !important;
}